import { useStaticQuery, graphql } from "gatsby";
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            github
            blog
            contactEmail
            contactPhone
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

// siteUrl
// headline
// description
// image
// video
// twitter
// name
// logo
