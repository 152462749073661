import React from "react";
import PropTypes from "prop-types";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import Header from "./header";
import Footer from "./footer";
const Layout = ({ children }) => {
  const { title, contactEmail, contactPhone } = useSiteMetadata();
  return (
    <>
      <body className="leading-normal tracking-normal text-white gradient">
        {/* Navigation */}
        <Header
          title={title}
          contactEmail={contactEmail}
          contactPhone={contactPhone}
        />
        {children}
        <Footer />
      </body>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
