import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";

function Header({ title, contactEmail, contactPhone }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav
      id="header"
      className="fixed w-full z-30 top-0 text-white bg-white shadow"
    >
      <div className="w-full container mx-auto flex flex-wrap items-left justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <a
            className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-gray-800"
            href="/"
          >
            {title}
          </a>
        </div>

        <div
          className="w-full flex-grow lg:flex lg:items-right lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            {contactPhone ? (
              <li className="mr-3">
                <span className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4">
                  {contactPhone}
                </span>
              </li>
            ) : null}
            {contactEmail ? (
              <li className="mr-3">
                <a
                  className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                  href={"mailto:" + contactEmail}
                >
                  {contactEmail}
                </a>
              </li>
            ) : null}
          </ul>
        </div>
      </div>

      <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  contactEmail: PropTypes.string,
  contactPhone: PropTypes.string,
};

export default Header;
